<template>
  <c-flex flex="1" flex-dir="column" overflow="auto" pb="30px">
    <c-box px="40px" py="40px">
      <c-heading
        color="black.900"
        fontSize="24px"
        fontWeight="700"
        marginBottom="40px"
        textTransform="uppercase"
      >
        Asupan Gizi
      </c-heading>
      <EditNutritionForm @submit="submit" v-model="nutrition" />
    </c-box>
  </c-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import clientIdMixin from "@/utils/clientId-mixins";
import EditNutritionForm from "./forms/edit-nutrition-form.vue";

export default {
  name: "NutritionEdit",
  components: { EditNutritionForm },
  data() {
    return {
      nutrition: {},
      programId: null,
    };
  },
  mixins: [clientIdMixin],
  computed: {
    ...mapGetters({
      asupanGizi: "mealPlan/asupanGizi",
    }),
  },
  watch: {
    asupanGizi: {
      immediate: true,
      handler(val, old) {
        if (val !== old) {
          this.nutrition = { ...val };
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getAsupanGizi: "mealPlan/getAsupanGizi",
      updateAsupanGizi: "mealPlan/updateAsupanGizi",
      postAsupanGizi: "mealPlan/postAsupanGizi",
    }),
    async submit() {
      let data = {
        ...this.nutrition,
        clientId: this.clientId,
        currentcarbohydrateIntake: this.nutrition.currentCarbohydrateIntake,
        programId: this.programId,
      };

      let promise;
      if (this.nutrition?.id != null) {
        promise = this.updateAsupanGizi({ asupanGizi: data, clientId: this.clientId, });
      } else {
        promise = this.postAsupanGizi({asupanGizi: data, clientId: this.clientId});
      }
      await promise.then(() => this.$router.push({
        name: "nutri.profile-gizi",
        params: { clientId: this.clientId },
      }));
    },
  },
  async mounted() {
    this.getAsupanGizi(this.clientId);
    let program = await this.$store.dispatch("mealPlan/getLatestProgramForNutri", this.clientId);
    this.programId = program?.id;
  },
};
</script>
