<template>
  <c-box w="100%" maxWidth="590px">
    <EnergiForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
      :aktivitas-fisik="profileGizi.physicalActivity"
      :defisit-kalori="profileGizi.calorieDeficit"
      :kebutuhan-energi="profileGizi.basalEnergyNeeds"
      :tambahan-kalori="profileGizi.extraMaternalCalories"
    />
    <ProteinForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
      :weight="profileGizi.weight"
      :kebutuhan-asupan-energi="value.energyIntakeNeeds"
    />
    <LemakForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
      :kebutuhan-asupan-energi="value.energyIntakeNeeds"
    />
    <KarbohidratForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
      :kebutuhan-asupan-energi="+value.energyIntakeNeeds"
      :kebutuhan-lemak="+value.fatNeedsConstant"
      :kebutuhan-protein="+value.proteinNeedsConstant"
    />
    <SeratForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
    />

    <c-form-control marginBottom="30px" display="flex">
      <c-button
        backgroundColor="superLightGray.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="darkGray.900"
        marginRight="20px"
        marginBottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        backgroundColor="brand.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
import EnergiForm from "@/views/meal-plan/forms/asupan-gizi-form/energi-form";
import ProteinForm from "@/views/meal-plan/forms/asupan-gizi-form/protein-form";
import LemakForm from "@/views/meal-plan/forms/asupan-gizi-form/lemak-form";
import KarbohidratForm from "@/views/meal-plan/forms/asupan-gizi-form/karbohidrat-form";
import SeratForm from "@/views/meal-plan/forms/asupan-gizi-form/serat-form";
import { mapGetters } from "vuex";
import clientIdMixins from "@/utils/clientId-mixins";

export default {
  name: "EditNutritionForm",
  props: ["value"],
  mixins: [clientIdMixins],
  components: {
    KarbohidratForm,
    LemakForm,
    ProteinForm,
    EnergiForm,
    SeratForm,
  },
  computed: {
    ...mapGetters({
      profileGizi: "mealPlan/profileGizi",
    }),
  },
  async mounted() {
    // get profile gizi client
    await this.$store.dispatch("mealPlan/getProfileGizi", this.clientId);
  },
};
</script>
